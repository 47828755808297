

function Card({mods, children}) {


    return (
        <div className={`card${mods != null && mods.length > 0 ? " " + mods : ""}`}>
            {children}
        </div>
    );
}

export default Card