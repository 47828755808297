export default function ComingSoon() {
    return (
        <div id="comingSoon">
            <div>
                <h1>Coming Soon!</h1>
                <p>It looks like the version of this site for your device is still under construction. Sorry for the inconvenience! You can still view it on Desktop!</p>
                <p>~ Cameron McCarty</p>
            </div>
        </div>
    );
}