export default function NavBtn({ navType=0, scrollToSection, currRef, children }) {
    
    const handleClick = () => {
        scrollToSection(currRef);
    }
    if (navType === 0) {
        return (
            <div className={`navBtnWrapper`}>
                <div className={`navBtn`} onClick={handleClick}>{children}</div>
                <div className="navBtnHoverBar"><div className='hoverColor' /></div>
            </div>
        );
    }
    else {
        return (
            <div className={`navBtnWrapper`}>
                <div className="navBtnHoverBar"><div className='hoverColor' /></div>
                <div className={`navBtn`} onClick={handleClick}>{children}</div>
            </div>
        );
    }
}